import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import PSPDFKit, { Instance } from 'pspdfkit';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  @Input() pdfUrl: string;
  @Input() canSave: boolean;
  @Input() showSignaturesStatus: boolean = false;

  @Output() pdfEvent = new EventEmitter<any>();

  instance: Instance;

  ngOnInit(): void {
    PSPDFKit.load({
      baseUrl: location.protocol + '//' + location.host + '/assets/',
      document: this.pdfUrl,
      container: '#pspdfkit-container',
      licenseKey: environment.PDFKitLicense,
      initialViewState: new PSPDFKit.ViewState({
        readOnly: true,
      }),
    })
      .then((instance) => {
        this.instance = instance;
        if (this.showSignaturesStatus) {
          instance.setViewState((viewState) =>
            viewState.set('showSignatureValidationStatus', PSPDFKit.ShowSignatureValidationStatusMode.IF_SIGNED)
          );
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  async downloadFile() {
    const arrayBuffer = await this.instance.exportPDF();
    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    this.pdfEvent.emit(blob);
  }

  async addPdf(e: any) {
    const file: File = e?.target?.files?.[0];
    if (!file) {
      return;
    }
    await this.instance.applyOperations([
      {
        type: 'importDocument',
        afterPageIndex: this.instance.totalPageCount,
        treatImportedDocumentAsOnePage: false,
        document: file,
      },
    ]);
  }
}
