import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { SocketService } from '../app/services/sockets-service/sockets.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'NNCVS';

  constructor(
    private offcanvasService: NgbOffcanvas,
    private router: Router,
    private socketService: SocketService,
    private toastr: ToastrService
  ) {
    this.handleRouteEvents();
  }

  ngOnInit(): void {
    this.socketService.receiveWarning().subscribe((message: string) => {
      this.toastr.warning(message, 'Warning', {
        positionClass: 'toast-top-right',
        closeButton: true,
        timeOut: 10000,
      });
    });
  }

  handleRouteEvents() {
    this.router.events.subscribe((event) => {
      this.offcanvasService.dismiss();
    });
  }
}
