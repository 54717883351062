import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiCallingService } from 'src/app/shared/generic-api-calling.service';

@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.scss'],
})
export class ViewDocumentComponent {
  @Input()
  documentId: any;

  docId: any;
  source: any;
  docJwt: any;

  constructor(
    private _apiService: ApiCallingService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.onInit();
  }

  onInit() {
    this.docId = this.route.snapshot.paramMap.get('id') || this.documentId;
    this.source = this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://chat.centralizedverification.com/documents/view/${this.docId}`
    );
    // this.source = this.sanitizer.bypassSecurityTrustResourceUrl(`http://localhost:3000/documents/view/${this.docId}`);
  }
}
