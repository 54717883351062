import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import io, { Socket } from 'socket.io-client';
import { ICallUser } from 'src/app/interfaces/user-call.interface';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  public userJoined = new BehaviorSubject<ICallUser>(null);
  public userLeavedId = new BehaviorSubject<string>(null);
  public endMeeting = new BehaviorSubject(false);
  public socket: Socket;
  public currentUser: ICallUser;

  usersConnected: ICallUser[] = [];

  constructor() {
    this.socket = io(environment.socketUrl, { path: '/socket', secure: true });
    this.onAdminLeft();
    this.onUserList();
    this.onUserConnect();
    this.onUserDisconnect();
  }

  emit(event: string, ...args: any[]) {
    return this.socket.emit(event, ...args);
  }

  on(event: string, listener: (...args: any[]) => void) {
    return this.socket.on(event, listener);
  }

  emitJoinMeeting(userData: ICallUser): void {
    userData.userSocketId = this.socket.id;
    this.currentUser = userData;
    this.socket.emit('join-meeting', userData);
  }

  emitMeetingLeft(meetingId: string): void {
    this.socket.emit('meeting-left', meetingId);
  }

  emitControlCamera(socketId: string, enable: boolean) {
    this.socket.emit('control-camera', { socketId, enable });
  }

  emitControlMicrophone(socketId: string, enable: boolean) {
    this.socket.emit('control-microphone', { socketId, enable });
  }

  private onAdminLeft() {
    this.socket.on('admin-left', (userSocketId: any) => {
      this.userLeavedId.next(userSocketId);
      this.endMeeting.next(true);
    });
  }

  private onUserList() {
    this.socket.on('user-list', (users) => {
      for (const user of users) {
        const index = this.usersConnected.findIndex((u) => u.id === user.id && u.role === user.role);
        if (index !== -1) {
          this.usersConnected[index] = user;
        } else {
          this.usersConnected.push(user);
        }
        this.userJoined.next(user);
      }
    });
  }

  private onUserConnect() {
    this.socket.on('user-connected', (user: ICallUser) => {
      const index = this.usersConnected.findIndex((u) => u.userSocketId === user.userSocketId);
      if (index !== -1) {
        this.usersConnected.splice(index, 1);
      }
      this.userJoined.next(user);
    });
  }

  fromEvent<T>(eventName: string): Observable<T> {
    return new Observable<T>((observer) => {
      this.socket.on(eventName, (data: T) => {
        observer.next(data);
      });

      return () => this.socket.off(eventName);
    });
  }

  fromEventOnce<T>(eventName: string): Observable<T> {
    return new Observable<T>((observer) => {
      this.socket.once(eventName, (data: T) => {
        observer.next(data);
        observer.complete();
      });
    });
  }

  private onUserDisconnect() {
    this.socket.on('user-disconnected', (userSocketId: string) => {
      this.userLeavedId.next(userSocketId);
    });
  }

  public receiveWarning(): Observable<string> {
    return new Observable<string>((observer) => {
      this.socket.on('receive-warning', (message: string) => {
        observer.next(message);
      });
    });
  }

  public sendWarning(message: string) {
    this.socket.emit('send-warning', message);
  }
}
