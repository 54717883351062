export const environment = {
  production: false,
  baseUrl: 'https://staging-backend.centralizedverification.com/api/v1',
  baseUrlPrefix: 'https://staging-backend.centralizedverification.com',
  documentEngine: 'https://staging-backend.centralizedverification.com/PDFDE/',
  pdfDocumentEngineUrl: 'https://staging-backend.centralizedverification.com/PDFDE/',
  socketUrl: 'https://staging-backend.centralizedverification.com',
  peerHost: 'staging-backend.centralizedverification.com',
  peerPort: '443',
  PDFKitLicense:
    'RorgIDhKFRnaUvE2xeF_RzV4rjUUqxKIC9FBF8LvyGWL4mqI1rduinxGlVzV0fF1Q8yuODud2bHkE-x4sTrFdaRNnbck26G-uDCvXl7bVFGUrcLzHsEgVvGF5UOPp-K2ai4to6zMtiDdotjjatEI9ok2_SLEs0hd86GN_d7b-ox90MJefWCmtL52-mQSLnUoOceg5JNeRY7AIxlgwXiETU4w5dME23NrWKYsMjs5eOiuqyRH1UwGfkldiKRKGclV2yrV2Gw9T4fwsEdntkve887onCB6kbKhzwdgj43QXd3rLBY4ckiG8-7EKx-fVjx9M-oRmwtXQGLJyO9L3TA1rgp-LGTklsEm_yYJaclf167BFePlzgCltFlX1PUWHrWOhhifBqWWJIrvhtp4qyzRPJDP1wLfBFrA6RjzZJx6_zbXxCRfEAQa9coC2FCCQY-e0g59T2NgY8ryga_pRVRG49smubJR9gGzGSY_lbWuZ9dfKOtP07ZavZig2GUa6dHhVfX9SgvLTdjTDbzY9dt3R3Vg7IMcJfjzGkqxMvGj2nS0yru0URCfVhAZhfpWag10o3rkEtZaExVCxY9ZJGkmRjpMm3RNJPqaVOY0DRCnRlzzb3FAwg4uRXGvHfZKdZvoyakGc-2y-63Xo8HIp6G77W0PYI2bvsJxN_DyYoLKscb-sduhQTaWJX012MhRRLeNAT3YtDVHVyGY37blhLw5VtnZyrbd9j7XkwGztUCGIf8=',
  // PDFKitLicense:
  //   'q0I1ZHlhgYIGY5JYq15ejEfMioJmcm5-HdXU1vyd9SMqt8lFGbtZfr5A-svc212_YYzgHNUm53IpHx1OYyQi1QkBBZHpbz4eYAO4U7N8WJ1ptssqEdTYtfpxOGPk5bkBJkQQN9JcJV6xyP4PLEFwiPW_SizT1Xa1yNj5RlxU_0oyk4WCeDpwK6WMQElLEHBwo_8zXjXIm0GvcQWjfNHm_NM99Ew37yRBeAIUgNnNV7ID0EU6SPGvWa-Wn2VUSU2tUJKv2oTk-ERsgInnOFmsdIfXTYIUhQ7e6FcJ0TLSneo8Vm-zxqCEguWTMMcqn_QFFB4O5eYSDmFInOE7h06Y6bU13ivFhN5nilirlV8PFlf59Y2O4aSjDTGxy_4GRpt_rsDPYVIsgiJnzywVsO_T8eo1Tc0vkzeEap8Z9ZK5W698-8T-QjEYuEMbIPAQSvrhsvq-2IjJqd-JnpdnyXjEjBy5ZaHnEAZdMbolrwOeRRPSxGiFmGZKRsKO4R74fIxg6b3OWnaESfdaVbjLs1q82bMZ-9A0PxHCkBmjh3XMAEeheKE8Ruuuil5j3tn_MHVRE03fHWfPGvE-iL9-EDtCKBfeguR6XHuIawLn62JaGKzj58nAHnpqOyUAcTlHEbWXpxWEnVaj5k8eP6MnftxjfFXF4mDw9zwE3JrCiPTaAGfRS1i76z1jKYJMkr816M93b7puJgaAxNzOCnKlh2XwHA==',
  stripePublickey:
    'pk_test_51Jpk6xJ4L10gXY7hnLA8yL8kkuBZcQZq34aBWPJAy5NqSE8SCiJzmseT9mmYsixOkmgPW4SsMntOOwX0tuyBRjGi00kf44KUru',
  terminalStripePublickey:
    'pk_live_51OKnnYGQwA6nOXDbGTV8746CbTsmFYOw1uh8VJ3rcq5LU7uz5Vwm8928g5nNTZxpuiw9I9x6rZJsz5vm7P0p7PVo00nSWkO0dY',
  recaptchaSiteKey: '6LdAywcqAAAAALAdKItB_mpTUAJEI8schsqEXUJg',
  documentEngineSecret: 'secret-token-header-pdf!nncvs',
  turnServer: 'turn:turn.staging-backend.centralizedverification.com:3478',
  turnUser: 'turn',
  turnPassword: 'nncvs5690',
};
