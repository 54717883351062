import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Instance } from 'pspdfkit';
import { ApiCallingService } from 'src/app/shared/generic-api-calling.service';

@Component({
  selector: 'app-document-sync',
  templateUrl: './document-sync.component.html',
  styleUrls: ['./document-sync.component.scss'],
})
export class DocumentSyncComponent implements OnInit {
  @Input() documentId: any;
  @Input() canSave: boolean;
  @Input() roomId: any;
  @Input() auth: any;
  @Input() url: any;
  @Input() connectedUsers: any;
  @Output() PDFKit = new EventEmitter<Instance>();
  @Output() pdfEvent = new EventEmitter<Blob>();

  docId: any;
  docJwt: any;

  constructor(
    private _apiService: ApiCallingService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.onInit();
  }

  onInit() {
    this.docId = this.route.snapshot.paramMap.get('id') || this.documentId;
  }

  emitPdfKit(value: Instance) {
    this.PDFKit.emit(value);
  }

  emitPdfEvent(value: Blob) {
    this.pdfEvent.emit(value);
  }
}
