import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/auth.guard';
import { DocumentSyncComponent } from './pages/document-sync/document-sync.component';
import { ViewDocumentComponent } from './pages/view-document/view-document.component';
const authmodule = () => import('./auth/auth.module').then((m) => m.AuthModule);
const home = () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule);
const pages = () => import('./pages/pages.module').then((m) => m.PagesModule);
const homeModule = () => import('./home/home.module').then((m) => m.HomeModule);
const callModule = () => import('./call/call.module').then((m) => m.CallModule);
const callV2Module = () => import('./call-v2/call-v2.module').then((m) => m.CallV2Module);
const notFoundModule = () => import('./not-found/page-not-found.module').then((m) => m.PageNotFoundModule);

const routes: Routes = [
  { path: '', loadChildren: authmodule },
  { path: 'dashboard', loadChildren: home, canActivate: [AuthGuard] },
  { path: 'page', loadChildren: pages, canActivate: [AuthGuard] },
  { path: 'auth', loadChildren: authmodule },
  { path: 'files', loadChildren: () => import('./files/files.module').then((m) => m.FilesModule) },
  {
    path: 'document/:id',
    component: DocumentSyncComponent,
  },
  {
    path: 'doc/:id',
    component: ViewDocumentComponent,
  },

  // { path: '**', redirectTo: '' },
  {
    path: 'complaintform',
    loadChildren: () => import('./complaint-form/complaint-form.module').then((m) => m.ComplaintFormModule),
  },
  {
    path: 'generate-stamp',
    loadChildren: () => import('./generate-stamp/generate-stamp.module').then((m) => m.NotaryStampModule),
  },
  {
    path: 'notary-search',
    loadChildren: () => import('./notary-filter/notary-filter.module').then((m) => m.NotaryFilterModule),
  },
  { path: 'call', loadChildren: homeModule },
  { path: 'call/:roomId', loadChildren: callV2Module },
  { path: 'meeting-end', loadChildren: () => import('./meeting-end/meeting.module').then((m) => m.MeetindEndModule) },
  { path: '**', loadChildren: notFoundModule },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
